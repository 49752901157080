
import React, { useState, useEffect } from 'react';
import CheckTable from './components/CheckTable';
import { columnsDataCheck, columnsDataDevelopment } from './variables/columnsData';
import DevelopmentTable from "./components/DevelopmentTable";

// ... other imports

const Tables = () => {
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10); // Adjust as needed
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [itemsPerPage, setItemsPerPage] = useState(10); // Adjust as needed
const [totalCount,setTotalCount]=useState(0)
  // const fetchUsers = async (skip = 0, take = 40) => { // Default values for pagination
  //   setIsLoading(true);
  //   setError(null);
  
  //   try {
  //     // Retrieve access token from local storage (assuming 'accessToken' key)
  //     const accessToken = localStorage.getItem('accessToken');
  //     if (!accessToken) {
  //       throw new Error('Missing access token'); // Handle missing token gracefully
  //     }
  
  //     const response = await fetch(
  //       `https://api.24six9.com/api/v1/user?skip=${skip}&take=${take}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`, // Include access token in headers
  //         },
  //       }
  //     );
  
  //     if (!response.ok) {
  //       throw new Error('Failed to fetch users');
  //     }
  
  //     const data = await response.json();
  //     console.log(data.data,"data")
  //     setUserList((prevUsers) => [...prevUsers, ...data.data]);
  //     setCurrentPage(data.meta.currentPage); // Update current page after fetch
  //     setItemsPerPage(data.meta.itemsPerPage)
  //     setTotalCount(data.meta.totalCount)
  //     setSkip(skip + take);
  //   } catch (err) {
  //     setError(err.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };



  const fetchUsers = async (page = 1, perPage = itemsPerPage) => {
    setIsLoading(true);
    setError(null);

    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        throw new Error('Missing access token');
      }

      const response = await fetch(
        `https://api.24six9.com/api/v1/user?skip=${(page - 1) * perPage}&take=${perPage}`, // Calculate skip based on page
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }

      const data = await response.json();
    setUserList((prevUsers) => [...prevUsers, ...data.data]);
      setCurrentPage(data.meta.currentPage); // Update current page after fetch
      setItemsPerPage(data.meta.itemsPerPage)
      setTotalCount(data.meta.totalCount)
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // Implement error handling and loading state in CheckTable component

  const handlePageChange = (e ,newPage) => {
    console.log(newPage)
    setCurrentPage(newPage);
    fetchUsers(newPage); // Fetch data for the new page
  };

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = e.target.value;
    // console.log("New items per page:", newItemsPerPage);
    // console.log(newItemsPerPage)
    setItemsPerPage(newItemsPerPage);
    fetchUsers(1, newItemsPerPage); // Reset page and fetch with new limit
  };


  return (
    <div>
      {isLoading ? (
        <p>Loading users...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
      <DevelopmentTable
          columnsData={columnsDataDevelopment}
          tableData={userList}

          handleItemsPerPageChange={handleItemsPerPageChange}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          totalCount={totalCount}
          itemsPerPage={itemsPerPage}
        />
      )}
    </div>
  );
};

export default Tables;